html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

body {
  /* Not changing theme just for this, but matching to MB monochrome light
  "gray" (e.g. water) makes panel transition's impact "behind" map less jank. */
  background-color: hsl(180deg 3% 83%) !important;
}

a {
  /* Same as theme.palette.secondary.light but avoids need for `withStyles` */
  color: #55a9c1;
  text-decoration: none;
}

/*
 Google-hosted CDN for early-access fonts (NKo only one not found):
 https://fonts.google.com/earlyaccess
*/
@font-face {
  font-display: swap;
  font-family: 'NKo';
  src: url('/fonts/NotoSansNKo-Regular.ttf') format('truetype');
}

@font-face {
  font-display: auto; /* not sure if this is the way to go but it seems okay */
  font-family: 'Gentium';
  src: url('/fonts/GentiumAlt.ttf') format('truetype');
}

/* GROSS warning here down. Super fragile but menus not customizable? 🤷‍♂ */
.MuiPopover-root[role='presentation']
  .MuiListItemText-root
  .MuiTypography-root {
  font-size: 0.9em;
}

/* TODO: reduce redundancy from here down... */
.MuiPopover-root[role='presentation'] .MuiListItem-root.MuiButtonBase-root {
  padding: 0 0.5em;
  font-size: 0.9em;
}

/* 'Add or remove columns' heading */
.MuiPopover-root[role='presentation'] .MuiListItem-root.Mui-disabled {
  font-size: 1.15em !important;
  padding: 0 0.75em;
  margin-bottom: -0.25em;
}

/* Checkboxes */
.MuiPopover-root[role='presentation'] .MuiCheckbox-root {
  padding: 0.25em 0.25em;
}

/* Autocomplete padding when there's an icon and a clear btn */
.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon
  .MuiInputBase-root.MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
  padding-right: 40px;
}
