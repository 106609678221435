/* CRED: for a lot of the <CSSTransition> stuff */
/* coursework.vschool.io/react-transitions-with-react-transition-group/ */
.fade-enter {
  opacity: 0;
  position: absolute;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: all 350ms linear 350ms;
}

.fade-exit.fade-exit-active {
  transform: translateY(20px);
  opacity: 0;
  transition: all 250ms linear;
}
